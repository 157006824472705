import React from 'react'
import { useState } from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function Demo() {

    const [data, setData] = useState({}); // data is an object
    const [selected, setSelected] = useState(null); // selected is a number
    const inputRef = useRef(null); // inputRef is a reference to the input element
    const [isLoading, setIsLoading] = useState(false); // isLoading is a boolean

    // functions for handling radio buttons
    const handleClickSemi = () => {
        setSelected(1);
    };

    // functions for handling radio buttons
    const handleClickAuto = () => {
        setSelected(2);
    };

    // function for handling change in input fields
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const [PhoneNumber, setPhoneNumber] = useState()


    // form is a reference to the form element+
    const form = useRef();

    // function for sending email
    const sendEmail = (e) => {
        e.preventDefault();
        setData({
            ...data,
            phone: PhoneNumber
        })

        setIsLoading(true);

        const email = data.email;
        const url = `https://api.hunter.io/v2/email-verifier?email=${email}&api_key=a35540079908a5cca6b9b292bfc72e47fb19b768`;
        fetch(url)
            .then((res) => res.json())
            .then((JsonData) => {
                if (JsonData.data.result === "deliverable") {
                    // if the email is valid
                    setIsLoading(false);
                    // diable the button  and show the loading animation

                    emailjs.sendForm(
                        "service_00d0u96",
                        "template_krqcwo3",
                        form.current,
                        "X_lrAJW-SL0NZfiwg"
                    );

                    notify(); // notify the user
                    e.target.reset(); // reset the form
                    setSelected(null); // reset the radio buttons
                }
                else {
                    setIsLoading(false);
                    toast.error("Invalid Email", {
                        position: toast.POSITION.TOP_CENTER,
                    });

                    // error handling for invalid email

                    inputRef.current.focus();

                    // focus red on the input element
                }
            });
    };

    // function for notifying the user
    const notify = () => {
        toast.success("Request sent", {
            position: toast.POSITION.BOTTOM_CENTER,
        });
    };

    return (
        <>
            <div className="form__container">
                <div className="form__container__heading">
                    <h1>Book a demo</h1>
                </div>
                <form ref={form} className="form__container__form" onSubmit={sendEmail}>


                    {/* Personal Details */}

                    <div className="form__container__form__personal_details">

                        {/* element only for getting value of phone number with country code */}
                        <input type="text" value={PhoneNumber} name="phone" style={{
                            display: "none"
                        }} />


                        <div className="form__container__form__heading">
                            <h2>Personal Details</h2>
                        </div>
                        <div className="row">
                            <input
                                className="form__container__form__input"
                                type="text"
                                autoComplete="off"
                                list="autocompleteOff" // disable autocomplete
                                name="name"
                                placeholder="Enter your name"
                                onChange={handleChange}
                                required
                            ></input>
                            <input
                                className="form__container__form__input"
                                type="email"
                                autoComplete="off" // disable autocomplete
                                ref={inputRef}
                                name="email"
                                placeholder="Enter your email"
                                onChange={handleChange}
                                required
                            ></input>
                        </div>
                        <div className="row">
                            {/* <input
                                className="form__container__form__input"
                                type="text"
                                name="phone"
                                placeholder="Enter your phone number"
                                onChange={handleChange}
                                required
                            ></input> */}
                            <PhoneInput
                                defaultCountry="IN"
                                autoComplete="off"
                                value={PhoneNumber}
                                onChange={setPhoneNumber}
                                placeholder="Phone Number"
                                className="form__container__form__input_Phone"
                                required
                            />
                            <input
                                className="form__container__form__input PhoneInputInput"
                                type="text"
                                autoComplete="off" // disable autocomplete
                                name="post"
                                placeholder="Enter your post"
                                onChange={handleChange}
                                required
                            ></input>
                        </div>
                    </div>


                    {/* Company details */}

                    <div className="form__container__form__personal_details">
                        <div className="form__container__form__heading">
                            <h2>Company Information</h2>
                        </div>
                        <div className="row">
                            <input
                                className="form__container__form__input"
                                type="text"
                                autoComplete="off" // disable autocomplete
                                name="company_name"
                                placeholder="Company Name"
                                onChange={handleChange}
                                required
                            ></input>
                            <input
                                className="form__container__form__input"
                                type="number"
                                autoComplete="off" // disable autocomplete
                                name="utility_plants"
                                placeholder="Number of Utility Plants"
                                onChange={handleChange}
                                required
                            ></input>
                        </div>
                    </div>

                    {/* Demo site details */}

                    <div className="form__container__form__personal_details">
                        <div className="form__container__form__heading">
                            <h2>Demo Site Information</h2>
                        </div>
                        <div className="row">
                            <input
                                className="form__container__form__input"
                                type="text"
                                autoComplete="off" // disable autocomplete
                                name="plant_location"
                                placeholder="Site Location"
                                onChange={handleChange}
                                required
                            ></input>
                            <input
                                className="form__container__form__input"
                                type="text"
                                autoComplete="off" // disable autocomplete
                                name="cleaning_solution"
                                placeholder="Current Cleaning Solution"
                                onChange={handleChange}
                                required
                            ></input>
                        </div>
                        <div className="row">
                            <input
                                className="form__container__form__input"
                                type="text"
                                autoComplete="off" // disable autocomplete
                                name="plant_capacity"
                                placeholder="Plant Capacity in Megawatts"
                                onChange={handleChange}
                                required
                            ></input>
                            <input
                                className="form__container__form__input"
                                type="number"
                                autoComplete="off" // disable autocomplete
                                name="number_of_robots"
                                placeholder="Number of Robots required"
                                onChange={handleChange}
                                required
                            ></input>
                        </div>

                    </div>

                    {/* Type of robots required */}

                    <div className="form__container__form__input_radio">
                        <div className="row_radio">
                            <label
                                htmlFor="semi"
                                onClick={handleClickSemi}
                                className={
                                    selected === 1 ? "radio_option selected" : "radio_option"
                                }
                            >
                                <input
                                    type="radio"
                                    value={"Semi Automatic Robots"}
                                    name="robot_type"
                                    id="semi"
                                    onChange={handleChange}
                                    required
                                />
                                <div style={{ marginLeft: "8px" }} className="radio_content">
                                    <p
                                        className="extrasmallHeading"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Semi Automatic Robots
                                    </p>
                                    <p className="para">
                                        Fully autonomous robots are built for longer rows and automated processes, removing the need for manual labour, which may be expensive and time-consuming.
                                    </p>
                                </div>
                            </label>
                            <label
                                htmlFor="auto"
                                onClick={handleClickAuto}
                                className={
                                    selected === 2 ? "radio_option selected" : "radio_option"
                                }
                            >
                                <input
                                    type="radio"
                                    value={"Fully Automatic Robots"}
                                    name="robot_type"
                                    id="auto"
                                    onChange={handleChange}
                                    required
                                />
                                <div
                                    style={{ marginLeft: "8px" }}
                                    className="radio_content"
                                    onClick={handleClickAuto}
                                >
                                    <p
                                        className="extrasmallHeading"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Fully Automatic Robots
                                    </p>
                                    <p className="para">
                                        Semi-automatic robots are specifically developed for cleaning smaller rows and can move easily from one location to another, making them suitable for cleaning multiple rows of solar plant.
                                    </p>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="form__container__form__submit">
                        <div className="form__container__form__submit--content">
                            <p className="footerHeading">Our team will reach out to you</p>
                            <button
                                className="form__container__form__submit__button"
                                disabled={isLoading}
                            >
                                Send Request
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </>
    )
}
