import React from "react";
import Demo from "./Components/Demo";
import Quotation from "./Components/Quotation";

export default function App() {
  return (
    <div>
      <Demo/>
    </div>
  );
}